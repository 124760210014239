<template>
  <ion-page><cyan-page-wrapper :title="'Elegir ' + nombreHijo" :burst="true" :backRoute="backRoute" nav1="MODO PARTIDO">
    <mapa-sv :nextRoute="nextRoute" :key="'mapaPartyPicker'" v-if="seed == base"/>
    <territory-picker :nextRoute="nextRoute" :seedTP="seed" :key="'partyPicker-' + seed"/>
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, { defineComponent, IonPage } from '@/components/CyanPageWrapper.vue'
import TerritoryPicker from '@/components/TerritoryPicker.vue';
import MapaSv from '@/components/MapaSv.vue';
import cyanRegions, { divisiones } from '@/modules/cyanRegions';
import store from "@/modules/adaptedStorage";

export default defineComponent({
  name: "PartyPicker",
  components: {
    TerritoryPicker,
    CyanPageWrapper,
    IonPage,
    MapaSv
  },
  computed: {
    seed(): string {
      return (this.$route.params.seedPP as string) || 'SV';
    },
    targetLength(): number {
      return parseInt(this.$route.params.targetLength as string) || 2;
    },
    nextRoute(): string {
      const nextStepLength = divisiones[this.seed.length]['largoHijos'];
      if (this.targetLength == nextStepLength) {
        return '/partyList/';
      } else {
        return '/partyPicker/' + this.targetLength + '/';
      }
    },
    backRoute(): string {
      const prevStepLength = divisiones[this.seed.length].largoPadre;
      if (!prevStepLength || prevStepLength < store.state.userData.nivel.length) return '/partyLevels';
      return '/partyPicker/' + this.targetLength + '/' + this.seed.substring(0, prevStepLength);
    },
    base(): string {
      return cyanRegions.base;
    },
    nombreHijo(): string {
      const lh = divisiones[this.seed.length].largoHijos;
      if (lh) {
        return divisiones[lh].nombre;
      }
      return 'nivel';
    }

  },
  methods: {}
});
</script>
